import React, { useEffect } from "react";
import { Grid, Menu, MenuItem } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { paginationTickets } from "../../api/ticketController";
import { USER_DATA_KEY } from "../../utils/constants";
import DrawerDetailNotification from "../../components/notifications/DrawerDetailNotifications";
import DrawerNotifications from "../../components/notifications/DrawerNotifications";
import { formatDatePtBr } from "../../utils/dateUtils";
import { useHotel } from '../../contexts/HotelProvider';

const columns = [
  { id: "title", label: "Título", minWidth: 200 },
  { id: "description", label: "Descrição", minWidth: 500 },
  { id: "creator", label: "Autor", minWidth: 100 },
  { id: "date", label: "Criado em", minWidth: 100 },
  { id: "republish", label: "", minWidth: 0 },
  { id: "details", label: "", minWidth: 0 },
];
const styleRow = {
  fontSize: "14px",
  lineHeight: "20px",
  color: "#252733",
  fontWeight: "600",
};

const styleTitle = {
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "19px",
  lineHeight: "28px",
  letterSpacing: "0.4px",
  color: "#252733",
};

function TabItemNotification(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [itemSelected, setItemSelected] = React.useState(null);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [openNewNotification, setOpenNewNotification] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { hotel } = useHotel();
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    const userData = localStorage.getItem(USER_DATA_KEY);
    if (userData) {
      const userDataParsed = JSON.parse(userData);
      setUser(userDataParsed);
    }
  }, []);

  useEffect(() => {
    if(hotel) {
      fetchData(hotel.id, null, rowsPerPage, true);
    }
  }, []);

  async function fetchData(hotelUID, lastDoc, rowsPerPage, next) {
    setLoading(true);
    paginationTickets(hotelUID, lastDoc, rowsPerPage, next)
      .then((response) => {
        setRows(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleChangePage = (event, newPage) => {
    let next = newPage > page;
    let doc = null;
    if (rows.length > 0) {
      doc = next ? rows[rows.length - 1] : rows[0];
    }
    fetchData(hotel.id, doc, rowsPerPage, next);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    fetchData(hotel.id, null, event.target.value);
  };

  const showNotification = () => {
    setOpenNotification(true);
    handleClose();
  };

  const closeNotification = () => {
    setOpenNotification(false);
    setItemSelected(null);
  };

  const newNotification = () => {
    setOpenNewNotification(true);
    handleClose();
  };

  const closeNewNotification = () => {
    setOpenNewNotification(false);
    setItemSelected(null);

    props.reload();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <p style={styleTitle}>Histórico de notificações criadas</p>
        {loading ? (
          <Skeleton variant="rect" width={"100%"} height={134} sx={{ mt: 2, borderRadius: '4px', backgroundColor: '#E8EAEE' }} />
        ) : (
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          top: 57,
                          minWidth: column.minWidth,
                          backgroundColor: "#F5F6FA",
                          color: "#9FA2B4",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length > 0 &&
                    rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell style={styleRow}>{row.title}</TableCell>
                          <TableCell style={styleRow}>
                            {row.description}
                          </TableCell>
                          <TableCell style={styleRow}>
                            {row.create_by}
                          </TableCell>
                          <TableCell style={styleRow}>
                            {formatDatePtBr(new Date(row.create_at))}
                          </TableCell>
                          <TableCell align="right" style={styleRow}>
                            <div>
                              <MoreVertIcon
                                id="demo-positioned-button"
                                aria-controls={
                                  openMenu ? "demo-positioned-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={openMenu ? "true" : undefined}
                                onClick={(e) => {
                                  setItemSelected(row);
                                  handleClick(e);
                                }}
                              />
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem onClick={() => newNotification()}>
                                  Publicar novamente
                                </MenuItem>
                                <MenuItem onClick={() => showNotification()}>
                                  Ver detalhes
                                </MenuItem>
                              </Menu>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={
                rows.length !== rowsPerPage
                  ? rows.length
                  : props.countOpenNotifications
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Resultados por página"}
            />
          </Paper>
        )}
      </Grid>
      {openNotification ? (
        <DrawerDetailNotification {...itemSelected} close={closeNotification} />
      ) : null}
      {openNewNotification ? (
        <DrawerNotifications {...itemSelected} close={closeNewNotification} />
      ) : null}
    </Grid>
  );
}

export default TabItemNotification;
