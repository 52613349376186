import React, { useState } from 'react';
import {
  SwipeableDrawer, IconButton, TextField,
  Divider, FormControlLabel, FormControl, FormLabel, Radio, RadioGroup, Button, Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { updateRequest } from '../../api/requestsController';
import { USER_DATA_KEY } from '../../utils/constants';
import StatusRequest from './StatusRequest'
import { toast } from 'react-toastify';
import { useHotel } from '../../contexts/HotelProvider';

const styles = {
  title: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    color: '#252733',
    letterSpacing: '0.4px',
    lineHeight: '24px'
  },
  local: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: '#000000'
  },
  date: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: '#000000',
    opacity: '0.54'
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#E8EAEE",
    borderRadius: "8px"
  },
  buttonClose: {
    background: '#C4C4C4',
    borderRadius: '6px',
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px',
  },
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  box: {
    padding: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  padding: {
    padding: '12px',
  },
  itemBox: {
    width: '100%',
    marginBottom: '20px',
  },
  label: {
    fontSize: 14,
    fontWeight: 400
  },

  data: {
    fontSize: 14,
    fontWeight: 700
  },
  boxSugestionSulution: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    marginTop: 1
  },
}

const drawerWidth = 450;

function DrawerRequest(props) {
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState(props['status'] || '');
  const [solution, setSolution] = useState(props['solution'] || '');
  const [sugestionSelected, setSugestionSelected] = useState(null);
  
  const { hotel } = useHotel()
  const readonly = props['status'] === 'done'

  const sugestions = [
    { id: 1, message: "Olá! Resolvemos o problema e pedimos desculpas pelo incoveniente." },
    { id: 2, message: "Olá! Chegando no local vimos que não tinha nenhum problema. Pode passar na recepção para vermos juntos?" },
    { id: 3, message: "Olá! Infelizmente não conseguimos resolver no momento, mas estamos trabalhando para que se resolva o mais rápido possível." },
  ]

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
    props.close()
  };

  const save = () => {
    if (readonly) {
      toast.error("Operação não permitida, esse chamado já foi finalizado!")
      return
    }
    
    const request = { ...props }
    request.status = status
    request.solution = solution

    updateRequest(hotel.id, request)
      .then(() => {
        toast.success("Operação realizada com sucesso")
      }).catch(() => {
        toast.error("Erro, tente novamente mais tarde!")
      })
    if (props.reload) {
      props.reload()
    }
    props.close()
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    save()
  };

  const onSelectSolution = (solution) => {
    setSolution(solution.message)
    setSugestionSelected(solution.id)
  };


  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: '1300',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton
            component="span"
            onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.title}>Chamado</span>
      </div>
      <Divider />

      <form onSubmit={handleSubmit}>
        <div style={styles.box}>
          <h3>
            Detalhes do chamado
          </h3>

          <Box
            component="div"
            sx={[styles.rowBetween, { marginBottom: 0.5 }]}
          >
            <div style={{ flexDirection: 'row' }}>
              <span style={styles.label}>
                Nome:
              </span>
              <span style={styles.data}>
                {` ${props.user_name}`}
              </span>
            </div>

            <div style={{ flexDirection: 'row' }}>
              <span style={styles.label}>
                Quarto:
              </span>
              <span style={styles.data}>
                {` ${props.user_room}`}
              </span>
            </div>

          </Box>

          <Box
            component="div"
            sx={styles.boxColumn}
          >
            <Box
              component="div"
              sx={styles.rowBetween}
            >
              <span style={styles.date}>
                Criado em {props.createAt}
              </span>
              <StatusRequest status={status} />
            </Box>
            <span style={styles.title}>
              {props.problem}
            </span>
            <span style={styles.local}>
              {props.local}
            </span>
          </Box>

          <Box
            component="div"
            sx={{
              marginTop: '20px'
            }}
          >
            <FormControl
            >
              <FormLabel id="row-radio-buttons-group-label">Situação</FormLabel>
              <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <FormControlLabel value="open" control={<Radio />} label="Aberto" disabled={readonly} />
                <FormControlLabel value="inprogress" control={<Radio />} label="Andamento" disabled={readonly} />
                <FormControlLabel value="done" control={<Radio />} label="Finalizado" disabled={readonly} />
              </RadioGroup>
            </FormControl>

            {status === 'done' ?
              <>
                <h3>
                  Qual foi a Solução?
                </h3>

                <TextField
                  id="outlined-basic"
                  label="solução"
                  variant="outlined"
                  required
                  multiline
                  rows={4}
                  placeholder="solução"
                  style={styles.itemBox}
                  value={solution}
                  disabled={readonly}
                  onChange={(e) => setSolution(e.target.value)}
                />

                <FormLabel>Repostas automáticas</FormLabel>

                {!readonly &&
                  sugestions.map((sugestion) => {
                    return (
                      <Box
                        component="div"
                        sx={[styles.boxSugestionSulution, { backgroundColor: sugestion.id == sugestionSelected ? '#ECF1FE' : '#F5F5F5' }]}
                        onClick={() => onSelectSolution(sugestion)}
                      >
                        <span>{sugestion.message}</span>
                      </Box>
                    )
                  })}
              </>
              : null}

          </Box>

          <Button
            variant='contained'
            elevation={0}
            style={{ marginTop: '25px', height: 42 }}
            type="submit"
            disabled={readonly}
          >
            Atualizar
          </Button>
        </div>
      </form>
    </SwipeableDrawer>
  )
}

export default DrawerRequest;
