import React, { useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Skeleton from "@mui/material/Skeleton";
import { paginationRatings } from '../api/rateController';
import DrawerRating from './DrawerRating';
import { formatDatePtBr } from '../utils/dateUtils';
import ButtonStyle from '../styles/button.style';
import { useHotel } from '../contexts/HotelProvider';

const columns = [
  { id: 'name', label: 'Nome do hóspede', minWidth: 350 },
  { id: 'comment', label: 'Comentário', minWidth: 500 },
  {
    id: 'date',
    label: 'Data',
    minWidth: 180,
    align: 'center'
  },
  { id: 'status', label: '', minWidth: 50 },
];

const styleRow = {
  fontSize: '14px',
  lineHeight: '20px',
  color: '#252733',
  fontWeight: '600',
}

const styleTitle = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '19px',
  lineHeight: '28px',
  letterSpacing: '0.4px',
  color: '#252733',
}

function TabRatings(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [itemSelected, setItemSelected] = React.useState(null);
  const [openRating, setOpenRating] = React.useState(false);
  const { hotel } = useHotel();
  
  useEffect(() => {
    fetchData(null, rowsPerPage, true);
  }, []);

  async function fetchData(lastDoc, rowsPerPage, next) {
    if(!hotel) {
      return
    }
    
    setLoading(true);

    paginationRatings(hotel.id, lastDoc, rowsPerPage, next)
      .then(response => {
        setRows(response.data);
      }).finally(() => {
        setLoading(false);
      })
  }

  const handleChangePage = (event, newPage) => {
    let next = newPage > page
    let doc = null;
    if (rows.length > 0) {
      doc = next ? rows[rows.length - 1] : rows[0]
    }
    fetchData(doc, rowsPerPage, next);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    fetchData(null, event.target.value, true);
  };

  const showRating = (item) => {
    setItemSelected(item);
    setOpenRating(true);
  }

  const closeRating = () => {
    setOpenRating(false);
    setItemSelected(null);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <p style={styleTitle}>
          Avaliações
        </p>
        {loading
          ?
          <Skeleton variant="rect" width={"100%"} height={134} sx={{ mt: 2, borderRadius: '4px', backgroundColor: '#E8EAEE' }} />
          :
          <Paper sx={{ width: '100%' }}>
            <TableContainer>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          top: 57,
                          minWidth: column.minWidth,
                          backgroundColor: '#F5F6FA',
                          color: '#9FA2B4',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length > 0 && rows
                    .map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          <TableCell style={styleRow}>
                            {row.user}
                          </TableCell>
                          <TableCell style={styleRow}>
                            {row.comment}
                          </TableCell>
                          <TableCell align="center" style={styleRow}>
                            {row['timestamp_to_date'] && formatDatePtBr(new Date(row['timestamp_to_date']))}
                          </TableCell>
                          <TableCell style={styleRow}>
                            <Button
                              variant='contained'
                              style={ButtonStyle.default}
                              elevation={0}
                              onClick={() => showRating(row)}
                            >
                              Ver avaliação
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={rows.length !== rowsPerPage ? rows.length : props.hotel.numRatings}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={'Resultados por página'}
            />
          </Paper>}
      </Grid>
      {openRating ? <DrawerRating {...itemSelected} close={closeRating} /> : null}
    </Grid>
  )
}

export default TabRatings;